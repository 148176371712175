import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Rating} from "@mui/lab";
import {useGridApiContext} from "@mui/x-data-grid";
import {Autocomplete, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {createFilterOptions} from "@mui/material/Autocomplete";

const filter = createFilterOptions();

export  function renderEtudiant(params) {
    return  <Typography>{params.row.etudiant}</Typography>
}


export function RenderEtudiantFreeSolo(props){
    const { id, row, value, field, hasFocus, listEtudiants, setListEtudiants ,setWrongInput } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef(null);
    const [open, toggleOpen] = React.useState(false);
    const [dialogValue, setDialogValue] = React.useState('');
    const [inputValue, setInputValue] = React.useState(value || '');

    const handleChange = (event, newValue) => {
        if(newValue === null){
            setWrongInput(true);
            return;
        }
        if(newValue.inputValue === undefined){

            if ( listEtudiants.some(etudiant => etudiant.title === newValue.title)) {
                apiRef.current.setEditCellValue({ id, field, value: newValue.title, });
                setWrongInput(false);
                return;
            }
            else {
                toggleOpen(true);
                setDialogValue(newValue.title);
            }
            }
        else {
            if ( listEtudiants.some(etudiant => etudiant.title === newValue.inputValue)) {
                apiRef.current.setEditCellValue({ id, field, value: newValue.inputValue, });
                setWrongInput(false);
                return;
            }
            else {
                toggleOpen(true);
                setDialogValue(newValue.inputValue);
            }
        }
    };
    const handleClose = () => {
        setDialogValue('');
        toggleOpen(false);
        setWrongInput(false);
    };

    
    const addEtudiant = () => {
        const dialogValueWithTitle = {title: dialogValue};
        setListEtudiants([...listEtudiants, dialogValueWithTitle]);
        apiRef.current.setEditCellValue({ id, field, value: dialogValue, });
        handleClose();
    };


    return (
        <React.Fragment>
            <Autocomplete
                value={value}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                onChange={handleChange}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            title: `Ajouter "${params.inputValue}"`});
                    }

                    return filtered;
                }}
                id="free-solo-dialog-demo"
                options={listEtudiants}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option?.title;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li key={key} {...optionProps} >
                            {/*{option.nom}*/}
                            {/*{option.inputValue ?  option.inputValue : option}*/}
                            {option?.title}
                        </li>
                    );
                }}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                            },
                        }}
                        {...params}
                        style={{ textAlign: 'center' }}
                        onKeyDown={(event) => {
                            if (event.key === ' ') {
                                event.stopPropagation();
                            }
                        }}
                    />
                )}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Ajout d'un étudiant</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous ajouter un étudiant ?
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        value={dialogValue}
                        onChange={(event) =>
                            setDialogValue(event.target.value)
                        }
                        label="NOM Prenom"
                        type="text"
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button type="submit" onClick={addEtudiant}>Ajouter</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

