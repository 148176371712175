import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Grid,
  Button,
  Autocomplete,
  IconButton,
  Divider, CircularProgress,
} from "@mui/material";
import { fetchDomaine } from "../../Shared/CallAPI/Fetch/fetchDomaine";
import { fetchParcours } from "../../Shared/CallAPI/Fetch/fetchParcours";
import { fetchPromotion } from "../../Shared/CallAPI/Fetch/fetchPromotion";
import { fetchSemestre } from "../../Shared/CallAPI/Fetch/fetchSemestre";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { updateFicheProjetTutore } from "../api_requests/updateFicheProjetTutore";
import IntervenantsTableTutore from "./IntervenantsTableTutore";
import { fetchRole } from "../../Shared/CallAPI/Fetch/fetchRole";
import { fetchType } from "../../Shared/CallAPI/Fetch/fetchType";
import { fetchActiveUsers } from "../../Shared/CallAPI/Fetch/fetchActiveUsers";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Close";
import {fetchDateMax} from "../../Shared/CallAPI/Fetch/fetchDateMax";
import dayjs from "dayjs";
import {deleteFiche} from "../api_requests/deleteFiche";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

export default function DialogProjetTutore({ openFiche, handlePopUp, data, loading, setLoading, closeDialog }) {
  const [editable, setEditable] = useState(false);
  const [intervenants, setIntervenants] = useState([]);
  const [newIntervenant, setNewIntervenant] = useState(null);
  const [updateIntervenant, setUpdateIntervenant] = useState(null);
  const [typeCours, setTypeCours] = useState(false);
  const [roles, setRoles] = useState([]);
  const [types, setTypes] = useState([]);

  const [ficheId, setFicheId] = useState("");
  const [codeEC, setCodeEC] = useState("");
  const [libelle, setLibelle] = useState("");
  const [domaine, setDomaine] = useState(null);
  const [promotion, setPromotion] = useState(null);
  const [parcours, setParcours] = useState(null);
  const [semestre, setSemestre] = useState(null);
  const [td, setTd] = useState(0);
  const [budget, setBudget] = useState(0);
  const [budgetParEtudiant, setBudgetParEtudiant] = useState(0);
  const [ects, setEcts] = useState(0);
  const [responsable, setResponsable] = useState(null);
  const [coefB, setCoefB] = useState(1);
  const [annee, setAnnee] = useState("");
  const [totalEqTD, setTotalEqTD] = useState(0);
  
  const [sumTD, setsumTD] = useState(0);
  const [disableAddInterv, setDisableAddInterv] = useState(false);
  
 

  const [errors, setErrors] = useState({
    td: false,
  });

  const [domaineData, setDomaineData] = useState([]);
  const [promotionData, setPromotionData] = useState([]);
  const [parcoursData, setParcoursData] = useState([]);
  const [semestreData, setSemestreData] = useState([]);
  const [openClosing, setOpenClosing] = useState(false);
  const [newDateMax, setNewDateMax] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  
  const [FinalTotalEqTD, setFinalTotalEqTD] = useState(0);

  useEffect(() => {
    fetchActiveUsers()
      .then((user) => setAllUsers(user))
      .catch((error) => console.log(error));
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    setTd(budgetParEtudiant / coefB);
  }, [budgetParEtudiant,coefB]);


  
  const closeFiche = () => {
    setIsModified(false);
    closeDialog();
    setLoading(true);
    };

  const fetchData = async () => {
    setLoading(true);
    document.body.style.cursor = "wait";

    const user = JSON.parse(localStorage.getItem("user"));
    if (user.role.id === 1) {
      setEditable(true);
    } else {
      setEditable(false);
    }
    
    const domaineResp = await fetchDomaine();
    setDomaineData(domaineResp);

    const parcoursResp = await fetchParcours();

    setParcoursData(parcoursResp);

    const promotionResp = await fetchPromotion();
    setPromotionData(promotionResp);

    const semestreResp = await fetchSemestre();
    setSemestreData(semestreResp);
    
    fetchDateMax()
        .then((response) => {
          setNewDateMax(dayjs(response.dateMax));
          if (dayjs().isAfter(dayjs(response.dateMax).add(1, 'day')) && user.role.id !== 1) {
            setDisableAddInterv(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });

    fetchRole()
        .then((roleResp) => {
          setRoles(roleResp);
        })
        .catch((error) => console.log(error));
    fetchType()
        .then((typeResp) => {
          // Map to have only the name of the type
          const nomTypes = typeResp.map((type) => type.nomType);
          setTypes(nomTypes);
        })
        .catch((error) => console.log(error));
    if(openFiche) {
      setLoading(false);
      
    }
    document.body.style.cursor = "default";
  };

  useEffect(() => {
    
    
      fetchData().then(() => {
        if (data) {
          setFicheId(data.id || "");
          setIntervenants(data.interventions || []);
          setCodeEC(data.codeEC || "");
          setLibelle(data.libelle || "");
          setPromotion(data.promotion || null);
          setParcours(data.parcours || null);
          setSemestre(data.semestre || null);
          setTd(data.td || 0);
          setBudget(data.budget || 0);
          setBudgetParEtudiant(data.budgetEtudiant || 0);
          setEcts(data.ects || 0);
          setCoefB(data.coefB || 1);
          setAnnee(data.anneeScolaire || "");

          const domainesFounded = domaineData.find(
              (domaine) => domaine.idDomaine === data.domaine.id,
          );
          setDomaine(domainesFounded || null);

          const responsableFinded = allUsers.find(
              (user) => user.id === data.responsable.id);
          setResponsable(responsableFinded || null);
        }


        setIntervenants(data?.interventions || []);
      });
    
  }, [data,navigate]);


  const handleSaveFiche = async () => {
    if (!domaine || !promotion || !parcours || !semestre || !responsable) {
      console.error("One or more required fields are null or undefined");
      toast.error("Un ou plusieurs champs obligatoires sont nuls ou indéfinis");
      return;
    }
    const ficheData = {
      id: data.id,
      codeEC,
      libelle,
      domaine: { id: domaine.idDomaine },
      promotion: { id: promotion.id },
      parcours: { id: parcours.id },
      semestre: { id: semestre.id },
      td: parseFloat(td),
      budget: parseFloat(budget),
      budgetEtudiant: parseFloat(budgetParEtudiant),
      ects: parseFloat(ects),
      responsable: { id: responsable.id },
      coefB: parseFloat(coefB),
    };
    
    try {
      //addNewIntervenant(ListofNewIntervenants);
      //todo Promise
      await updateFicheProjetTutore(ficheData, navigate);
      toast.success(" Fiche mise à jour avec succès");
      closeFiche();
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la mise à jour de la fiche");
    }
  };



  const handleNumericChange = (setter, errorKey) => (event) => {
    const value = event.target.value;
    setter(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorKey]: isNaN(value),
    }));
    setIsModified(true);
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setIsModified(true);
  };

  const handleAutocompleteChange = (setter) => (event, newValue) => {
    setter(newValue);
    setIsModified(true);
  };
  useEffect(() => {
    setFinalTotalEqTD(intervenants.reduce((sum, intervenant) => sum + intervenant.totalEqTD, 0));
  }, [intervenants]);

  const handleDeleteFiche = async () => {
    if (window.confirm("Voulez-vous vraiment supprimer cette fiche ?")) {
      try {
        const promise = deleteFiche(ficheId);

        toast.promise(promise, {
          loading: "Suppression de la fiche...",
          success: () => <Typography>Fiche supprimé</Typography>,
          error: () => <Typography>Erreur lors de la suppression</Typography>,
        });
        closeFiche();
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la suppression de la fiche");
      }
    }
  };

  
  
  return (

    <Dialog open={openFiche && !loading} onClose={closeFiche} maxWidth="lg" fullWidth>
      <Toaster />
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "raw",
            justifyContent: "space-between",
            marginBottom: "1vh",
          }}
        >
          <Typography
            variant="h5"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {" "}
            Fiche de projet tutore : {annee}
          </Typography>
          <IconButton onClick={closeFiche} color="primary">
            <CancelIcon />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "0.1%" }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Code EC"
              fullWidth
              value={codeEC}
              onChange={handleInputChange(setCodeEC)}
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <TextField
              label="Libellé"
              fullWidth
              value={libelle}
              onChange={handleInputChange(setLibelle)}
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              options={promotionData}
              getOptionLabel={(option) => option.nomPromotion}
              renderInput={(params) => (
                <TextField {...params} label="Promotion" />
              )}
              value={promotion}
              onChange={handleAutocompleteChange(setPromotion)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              options={domaineData}
              getOptionLabel={(option) => option.nomDomaine}
              renderInput={(params) => (
                <TextField {...params} label="Domaine" />
              )}
              value={domaine}
              onChange={handleAutocompleteChange(setDomaine)}
              isOptionEqualToValue={(option, value) =>
                option.idDomaine === value.idDomaine
              }
              fullWidth
              disabled={!editable}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              options={semestreData}
              getOptionLabel={(option) => option.nomSemestre}
              renderInput={(params) => (
                <TextField {...params} label="Semestre" />
              )}
              value={semestre}
              onChange={handleAutocompleteChange(setSemestre)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              options={parcoursData}
              getOptionLabel={(option) => option.nomParcour}
              renderInput={(params) => (
                <TextField {...params} label="Parcours" />
              )}
              value={parcours}
              onChange={handleAutocompleteChange(setParcours)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Budget"
              fullWidth
              value={budget}
              onChange={handleNumericChange(setBudget, "budget")}
              error={errors.budget}
              helperText={
                errors.budget ? "Veuillez entrer une valeur numérique" : ""
              }
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="ECTS"
              fullWidth
              value={ects}
              onChange={handleNumericChange(setEcts, "ects")}
              error={errors.ects}
              helperText={
                errors.ects ? "Veuillez entrer une valeur numérique" : ""
              }
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Heures de face à face</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
                options={allUsers.filter(user => (user.role === "Responsable EC" || user.role === "Responsable de domaine" || user.role === "Directeur"))}
              getOptionLabel={(option) => `${option.surname} ${option.name}`}
              renderInput={(params) => (
                <TextField {...params} label="Responsable" />
              )}
              value={responsable}
              onChange={handleAutocompleteChange(setResponsable)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              disabled={!editable}
            />
          </Grid>
          {editable && (
            <Grid item xs={12} sm={8}>
              <Typography variant="h6">Pondération :</Typography>
              {intervenants.length > 0 && (
                  <Typography variant="h8" color="red" >
                    Attention, la pondération ne peut être modifiée si des
                    intervenants sont déjà affectés 
                  </Typography>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <TextField 
                      sx = {{marginTop: "1vh"}}
                      label="Budget par étudiant"
                      fullWidth
                      value={budgetParEtudiant}
                      onChange={handleNumericChange(setBudgetParEtudiant, "budgetParEtudiant")}
                      error={errors.budgetParEtudiant}
                      helperText={
                        errors.budgetParEtudiant ? "Veuillez entrer une valeur numérique" : ""
                      }
                      disabled={!editable || intervenants.length > 0}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {/*faire un display flex pour les heures totales*/}
          <Grid item xs={12}>
            <Typography variant="h6">Total des heures allouées : </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Total EqTD : {FinalTotalEqTD.toFixed(2)}</Typography>
          </Grid>

          {/* Section des intervenants */}
          <Grid item xs={12}>
            <Typography variant="h6">Intervenants</Typography>
            <IntervenantsTableTutore
              allUsers={allUsers}
              editable={editable}
              intervenants={intervenants}
              setIntervenants={setIntervenants}
              newIntervenant={newIntervenant}
              setNewIntervenant={setNewIntervenant}
              typeCours={typeCours}
              roles={roles}
              types={types}
              setAllUsers={setAllUsers}
              updateIntervenant={updateIntervenant}
              setUpdateIntervenant={setUpdateIntervenant}
              ficheId={ficheId}
              annee={annee}
              disableAddInterv={disableAddInterv}
              dateMax={newDateMax}
              coefB = {coefB}
              domaines={domaineData}
              totalEqTD = {FinalTotalEqTD}
              tdFiche = {td}
              budgetFiche = {budget}
            />
          </Grid>
        </Grid>
      </DialogContent> 
      <DialogActions>
        {editable ? (
        <Box  sx={{ width : "100%" , display: "flex", flexDirection: "row", 
          justifyContent: "space-between", 
          // gap:"100px" 
        }} >
          <Box>
            <IconButton
                aria-label="close"
                onClick={handleDeleteFiche}
                disabled={ intervenants.length > 0}
            >
              <DeleteIcon/>
            </IconButton>
          </Box>
          <Box 
              sx={{ display : "flex", flexDirection:"raw" }}
          >
            <Button
                onClick={closeFiche}
                color="error"
                sx={{ marginRight: "1vw" }}
            >
              Annuler
            </Button>
            <Button
                onClick={handleSaveFiche}
                color="primary"
                variant="contained"
                sx={{ marginRight: "1%" }}
                disabled={(!isModified)}
            >
              Sauvegarder
            </Button>
          </Box>
          
        </Box>
        ) : (
        <Box sx={{ display: "flex", flexDirection: "raw" }}>
          <Button
              onClick={closeFiche}
              color="primary"
              sx={{ marginRight: "1vw" }}
          >
            Retour
          </Button>
        </Box>
      )}
      </DialogActions>
      
    </Dialog>

  );
}
