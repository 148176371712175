import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserRole } from "./api_requests/updateUserRole.js";
import { fetchAllUsers } from "../Shared/CallAPI/Fetch/fetchAllUsers";
import { fetchRole } from "../Shared/CallAPI/Fetch/fetchRole";
import TableContainer from "@mui/material/TableContainer";
import toast from "react-hot-toast";

import "../../style/Comptes.css";
import { tradFrGrid } from "../Shared/TxtFr";

export default function UpdateRoleGrid() {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const navigate = useNavigate();
  const [roleOptions, setRoleOptions] = useState([]);
  

  useEffect(() => {
    // Fetch users and filter by role "non attribué"
    fetchAllUsers()
      .then((users) => {
        // Users with role "Pas attribué"
        const unassignedUsers = users.filter(
          (user) => user.role === "Pas attribué",
        );
        // Users with other roles
        const otherUsers = users.filter((user) => user.role !== "Pas attribué");

        // Merge the two arrays
        const sortedUsers = [...unassignedUsers, ...otherUsers];
        setRows(sortedUsers);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        toast.error("Erreur lors du chargement des utilisateurs");
      });

    // Fetch role options
    fetchRole()
      .then((roles) => {
            const nomRoles = roles.map((role) => role.nomRole);
            setRoleOptions(nomRoles);
          })
      .catch((error) => console.log(error));
  }, [navigate]);

  const handleRoleUpdate = async (updatedRow) => {
    const requestBody = {
      newRole: updatedRow.role,
      userToUpdate: {
        mail: updatedRow.mail,
        surname: updatedRow.surname,
        name: updatedRow.name,
      },
    };

    const promise =  updateUserRole(requestBody).then((msg) => {
      return msg;
    });

    toast.promise(promise, {
      loading: "Mise à jour du rôle...",
      success: (msg) => <b>{msg.succes}</b>,
      error: (msg) => <b>{msg.error}</b>,
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    // Set the row in edit mode
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const handleSaveClick = (id) => async () => {
    // Set the row to view mode
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleCancelClick = (id) => () => {
    // Set the row to view mode and ignore modifications
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    // Update the row modes model
    setRowModesModel(newRowModesModel);
  };

  const handleProcessRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? updatedRow : row)),
    );

    try {
      await handleRoleUpdate(updatedRow);
    } catch (error) {
      console.log(error);
    }
    return updatedRow;
  };

  const columns = [
    { field: "surname", headerName: "Nom", width: 150 },
    { field: "name", headerName: "Prénom", width: 150 },
    { field: "mail", headerName: "Email", width: 250 },
    {
      field: "role",
      headerName: "Droits",
      width: 220,
      editable: true,
      type: "singleSelect",
      valueOptions: roleOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          // In edit mode
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: "primary.main" }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        // Not in edit mode
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            
          />,
        ];
      },
    },
  ];

  // Add strong title to each column
  columns.forEach((column) => {
    column.renderHeader = (params) => (
      <strong>{params.colDef.headerName}</strong>
    );
  });

  return (
    <Box className="update-role-grid-container">
      <TableContainer sx={{ maxHeight: "100%" }}>
        <DataGrid
          localeText={tradFrGrid}
          initialState={{
            sorting: {
              sortModel: [{ field: "role", sort: "asc" }],
            },
          }}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={handleProcessRowUpdate}
          clipboardCopyCellDelimiter=";"
        />
      </TableContainer>
    </Box>
  );
}
