import * as React from "react";
import Box from "@mui/material/Box";
import DrawerBar, { drawerCloseWidth } from "../Shared/DrawerBar";
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import CompteGrid from "./CompteGrid";
import UpdateRoleGrid from "./UpdateRoleGrid";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import "../../style/Comptes.css";

export default function Comptes() {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("user")).role;
    //if the user isn't Directeur
    if (role.nom !== "Directeur") {
      toast.error("Vous n'avez pas accès à cette page");
      navigate(-1);
    }
  }, [navigate]);
  return (
  <Box className = 'comptes'>
    <DrawerBar selected={"/comptes"} />
    <Box className = 'container-navbar-grid'>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="data grid tabs"
      >
        <Tab label="Activité" />
        <Tab label="Gestion des droits" />
      </Tabs>
      
      <Box className="grid-container">
        {tabIndex === 1 && <UpdateRoleGrid />}
        {tabIndex === 0 && <CompteGrid />}
      </Box>
    </Box>
  </Box>
  );
}
