import { verifyToken } from "../../../utils/utils";
import toast from "react-hot-toast";
import {handleApiResponse} from "../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;

export async function fetchFilters(navigate, filters) {
  return new Promise(async (resolve, reject) => {
  try {
    const req = await fetch(`${apiUrl}/api/getDashBoard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(filters),
    });
    // If the token is invalid
    const result = await handleApiResponse(
        req,
        () => fetchFilters(navigate, filters),
        true 
    );
    resolve(result);
  } catch (error) {
    // Handle any other errors
    reject(error.message);
  }
  });
}

