import { verifyToken } from "../../../../utils/utils";
import toast from "react-hot-toast";
import {handleApiResponse} from "../../../../utils/errorHandler";
const apiUrl = process.env.REACT_APP_API_URL;
export async function fetchActivityByDomainBySchoolYear(selectedSchoolYear) {
  return new Promise(async (resolve, reject) => {
    try {
  const req = await fetch(
    `${apiUrl}/api/getNumberOfHourPerDomaineBySchoolYear?schoolYear=${selectedSchoolYear.anneeScolaire}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  );
  // If the token is invalid
      // If the token is invalid
      const result = await handleApiResponse(
          req,
          () => fetchActivityByDomainBySchoolYear(selectedSchoolYear),
          true
      );
      resolve(result);
    } catch (error) {
      // Handle any other errors
      reject(error.message);
    }
  });
}