import DrawerBar from "../Shared/DrawerBar";
import * as React from "react";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "../../style/Variables.css";
import InputDateAndYears from "./InputDateAndYears.jsx";
import PromotionGrid from "./PromotionGrid";
import ParcoursGrid from "./ParcoursGrid";
import SemestreGrid from "./SemestreGrid";
import DomaineGrid from "./DomaineGrid";

export default function Variables() {
  const navigate = useNavigate();

  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("user")).role;
    //if the user isn't Directeur
    if (role.nom !== "Directeur") {
      toast.error("Vous n'avez pas accès à cette page");
      navigate("/home");
    }
  }, [navigate]);

  return (
    <Box className="variables-page">
      <DrawerBar selected={"/variables"} />
      <InputDateAndYears />
      <Box className="grid-variables-container">
        <PromotionGrid />
        <ParcoursGrid />
        <SemestreGrid/>
        <DomaineGrid />
      </Box>
    </Box>
  );
}
