import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import "../../style/drawer.css";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CoPresentRoundedIcon from "@mui/icons-material/CoPresentRounded";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import SettingsIcon from "@mui/icons-material/Settings";
import toast, { Toaster } from "react-hot-toast";
import logo from "../../data/logoGcuDecla-Red-Black.png";

export const drawerWidth = 240;
export const drawerCloseWidth = 73;
export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 240,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export default function DrawerBar({ selected }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const [userLevel, setUserLevel] = React.useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.length === 0 || localStorage.getItem("user") === null) {
      navigate("/");
    } else {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user.role.nom === "Directeur") {
        setUserLevel([0, 1, 2, 3, 4]);
      } else if (user.role.nom === "Administration") {
        setUserLevel([0, 2, 4]);
      } else if (user.role.nom === "Responsable de domaine") {
        setUserLevel([0, 2]);
      } else if (user.role.nom === "Responsable EC") {
        setUserLevel([0, 2]);
      } else if (user.role.nom === "Lecteur"){
        setUserLevel([0, 2]);
      } else if (
        user.role.nom === "Pas attribué" || 
          user.role.nom === "Obsolète"
      ) {
        setUserLevel([0]);
      } else {
        toast.error("Vous n'avez pas les droits pour accéder à l'application");
        navigate("/"); // Redirect to login page
      }
    }
  }, []);

  const handleDeco = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  const menuItems = [
    { idx: 0, text: "Profil", icon: <AccountBoxIcon />, link: "/home" },
    {
      idx: 1,
      text: "Tableau de bord",
      icon: <DashboardIcon />,
      link: "/dashboard",
    },
    {
      idx: 2,
      text: "Fiches de cours",
      icon: <ImportContactsIcon />,
      link: "/fiches",
    },
    {
      idx: 3,
      text: "Activité des intervenants",
      icon: <SupervisorAccountIcon />,
      link: "/comptes",
    },
    {
      idx: 4,
      text: "Intervenants",
      icon: <CoPresentRoundedIcon />,
      link: "/intervenants",
    },
  ];

  const handleClickLink = (event) => {
    const text = event.currentTarget.textContent;
    const index = menuItems.findIndex((item) => item.text === text);
    const link = menuItems[index].link;
    navigate(link);
  };

  let lowerButton = ["Déconnexion"];
  const user = JSON.parse(localStorage.getItem("user"));

  if (user !== null) {
    lowerButton =
      user.role.id === 1 ? ["Variables", "Déconnexion"] : ["Déconnexion"];
  }

  return (
    <Drawer
      className="drawer"
      variant="permanent"
      open={open}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Toaster />
      <DrawerHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {open && (
              <div className={"logo-container"}>
                <img src={logo} alt="logo" className={"logo-drawer"} />
              </div>
            )}
            <IconButton onClick={handleDrawer}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>

          {open && (
            <div className={"user-info-header"}>
              <b>
                {user.name && user.surname
                  ? user.name + " " + user.surname
                  : ""}
              </b>
              {user.role ? user.role.nom : ""}
            </div>
          )}
        </div>
      </DrawerHeader>

      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "80vh",
          gap: 5,
        }}
      >
        {menuItems.map((item, index) =>
          userLevel.includes(index) ? (
            <Tooltip
              key={item.text}
              title={item.text}
              placement={"right"}
              disableHoverListener={open}
            >
              <ListItem
                key={item.text}
                onClick={handleClickLink}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    height: "125%",
                    backgroundColor:
                      selected === item.link
                        ? "rgba(0, 0, 0, 0.08)"
                        : "inherit", // Highlight selected item
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ) : null,
        )}
      </List>

      <List>
        {lowerButton.map((text, index) => (
          <Tooltip
            key={text}
            title={text}
            placement="right-start"
            disableHoverListener={open}
          >
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={
                  text === "Déconnexion"
                    ? handleDeco
                    : () => navigate("/variables")
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    selected === "/variables" && text !== "Déconnexion"
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit", // Highlight selected item
                }}
              >
                <ListItemIcon
                  sx={{
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {text === "Déconnexion" ? (
                    <ExitToAppIcon />
                  ) : (
                    <SettingsIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Drawer>
  );
}
