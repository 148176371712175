import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DrawerBar from "../Shared/DrawerBar";
import GridBoard from "./GridBoard";
import FiltresPopUp from "./FiltresPopUp";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { fetchFilters } from "./api_requests/fetchFilters";
import { fetchDetails } from "./api_requests/fetchDetails";
import DetailsDialog from "./DetailsRowDialog";
import "../../style/Dashboard.css";

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [openDetail, setOpenDetail] = useState(false);
  const [rowData, setRowData] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [titleDialog, setTitleDialog] = useState("");

  // Vider le localStorage pour les filtres
  //   useEffect(() => {
  //       localStorage.removeItem("filtersDashboard");
  //   }, []);
  
  const handleClickDetails = (rowData) => {
    setRowData(rowData);
    setTrigger(trigger + 1); // Force the useEffect to rerun
  };

  // Fetch the detailed data for the selected row and open the dialog
  useEffect(() => {
    if (Object.keys(rowData).length !== 0) {
      const filters = {
        promotion: rowData.promotion || "",
        parcours: rowData.parcours || "",
        semestre: rowData.semestre || "",
        annee: rowData.annee || "",
      };

      // Generate the title based on the filters
      const filtersArray = [
        filters.promotion,
        filters.parcours,
        filters.semestre,
        filters.annee,
      ].filter(Boolean); // Remove empty strings
      
      const title = filtersArray.length > 0 ? filtersArray.join(" / ") : "Aucun filtre";
      setTitleDialog(title);

      // Fetch the details for the selected row
      fetchDetails(navigate, filters)
          .then((data) => {
            // Transform the data into a format suitable for the chart
            const transformedData = Object.values(data).map((item, index) => ({
              id: index,
              value: item.totalEqTD,
              label: item.domaine,
            }));

            setChartData(transformedData);
            setOpenDetail(true);
          })
          .catch((error) => {
            console.error("Error fetching details:", error);
            toast.error("Erreur lors de la récupération des détails.");
          });
    }
  }, [rowData, trigger, navigate]);

  const handleFiltres = () => {
    setOpen(!open);
  };

  // Fetch data for the gridboard when the component loads or filters change
  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("user")).role;
    if (role.id !== 1) {
      toast.error("Vous n'avez pas accès à cette page");
      navigate(-1);
      return;
    }

    if (!open) {
      const filtersDashboard = localStorage.getItem("filtersDashboard");
      if (filtersDashboard) {
        const filtersArray = JSON.parse(filtersDashboard);

        let newData = [];
        const promises = filtersArray.map((filter) => {
          return fetchFilters(navigate, filter)
              .then((res) => {
                const newRow = { ...res, id: `${filter.id}` };
                newData[filter.id] = newRow;
              })
              .catch((err) => {
                console.error("Error fetching data:", err);
              });
        });

        Promise.all(promises).then(() => {
          setData(newData);
        });
      }
    }
  }, [open, navigate]);

  return (
      <Box className="dashboard">
        <FiltresPopUp open={open} setOpen={setOpen} sx={{ width: "100%" }} />
        <DrawerBar selected={"/dashboard"} />
        <DetailsDialog
            open={openDetail}
            setOpen={setOpenDetail}
            chartData={chartData}
            titleDialog={titleDialog}
        />
        <Box className="grid-button-container">
          <Box>
            <Tooltip
                title="Ajouter, modifier ou supprimer des filtres"
                placement="right"
            >
              <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{ margin: "5%" }}
                  onClick={handleFiltres}
              >
                Filtres
              </Button>
            </Tooltip>
          </Box>
          <GridBoard data={data} handleClickDetails={handleClickDetails} />
        </Box>
      </Box>
  );
}
