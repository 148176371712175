import * as React from "react";
import Box from "@mui/material/Box";
import GridIntervenant from "./GridIntervenant";
import DrawerBar from "../Shared/DrawerBar";
import "../../style/Intervenants.css";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function Intervenants() {
  const navigate = useNavigate();

  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("user")).role;
    //if the user isn't Directeur or Administration
    if (role.nom !== "Directeur" && role.nom !== "Administration") {
      toast.error("Vous n'avez pas accès à cette page");
      navigate(-1);
    }
  }, []);
  return (
    <Box className="container">
      <DrawerBar selected={"/intervenants"} />
      <Box className="content-box">
        <GridIntervenant />
      </Box>
    </Box>
  );
}
