import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Tabs, Tab } from "@mui/material";
import { Toaster } from "react-hot-toast";
import FicheSimple from "./FichesSimple/FicheSimple";
import FicheProjetCollectif from "./FichesProjectColletif/FicheProjetCollectif";
import { fetchActiveUsers } from "../Shared/CallAPI/Fetch/fetchActiveUsers";
import FicheProjetTutore from "./FichesProjetTutore/FicheProjetTutore";
import {fetchDomaine} from "../Shared/CallAPI/Fetch/fetchDomaine";
import {fetchParcours} from "../Shared/CallAPI/Fetch/fetchParcours";
import {fetchPromotion} from "../Shared/CallAPI/Fetch/fetchPromotion";
import {fetchSemestre} from "../Shared/CallAPI/Fetch/fetchSemestre";
import {fetchAnnees} from "../Shared/CallAPI/Fetch/fetchAnnees";
import {useNavigate} from "react-router-dom";

export default function DialogFicheSimple({ openFiche, handlePopUp }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [domaineData, setDomaineData] = useState([]);
  const [parcoursData, setParcoursData] = useState([]);
  const [promotionData, setPromotionData] = useState([]);
  const [semestreData, setSemestreData] = useState([]);
  const [anneeData, setAnneeData] = useState([]);

  useEffect(() => {
    fetchActiveUsers()
      .then((user) => setAllUsers(user))
      .catch((error) => console.log(error));
    const fetchData = async () => {
      const domaineResp = await fetchDomaine();
      setDomaineData(domaineResp);

      const parcoursResp = await fetchParcours();

      setParcoursData(parcoursResp);

      const promotionResp = await fetchPromotion();
      setPromotionData(promotionResp);

      const semestreResp = await fetchSemestre();
      setSemestreData(semestreResp);

      const anneeResp = await fetchAnnees();
      setAnneeData(anneeResp);

      
    };

      fetchData();
  }, []);
  

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Dialog open={openFiche} onClose={handlePopUp} maxWidth="lg" fullWidth>
      <Toaster />
      <DialogTitle>Fiche de cours</DialogTitle>
      <DialogContent>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="data grid tabs"
        >
          <Tab label="Cours" />
          <Tab label="Projet collectif" />
          <Tab label="Projet individuel tutoré" />
        </Tabs>
      </DialogContent>
      {tabIndex === 0 && (
        <FicheSimple handlePopUp={handlePopUp} 
                     allUsers={allUsers} 
                     domaineData={domaineData} 
                     parcoursData={parcoursData} 
                     promotionData={promotionData}
                     semestreData={semestreData}
                     anneeData={anneeData}
        />
      )}

      {tabIndex === 1 && (
        <FicheProjetCollectif handlePopUp={handlePopUp} 
                              allUsers={allUsers}
                              parcoursData={parcoursData}
                              promotionData={promotionData}
                              semestreData={semestreData}
                              anneeData={anneeData}
        />
      )}
      {tabIndex === 2 && (
        <FicheProjetTutore handlePopUp={handlePopUp} 
                           allUsers={allUsers}
                           domaineData={domaineData}
                           parcoursData={parcoursData}
                           promotionData={promotionData}
                           semestreData={semestreData}
                           anneeData={anneeData}/>
      )}
    </Dialog>
  );
}
