import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { fetchImplicatedInterventions } from "../Shared/CallAPI/Fetch/fetchImplicatedInterventions";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";

const columns = [
  { id: "codeEc", label: "Code EC", minWidth: 100, align: "center" },
  {
    id: "CM",
    label: "CM FaF",
    minWidth: 50,
    align: "center",
  },
  {
    id: "TD",
    label: "TD FaF",
    minWidth: 50,
    align: "center",
  },
  {
    id: "TP",
    label: "TP FaF",
    minWidth: 50,
    align: "center",
  },
  {
    id: "TotalEqTD",
    label: "Total EqTD",
    minWidth: 70,
    align: "center",
  },
];

export default function InterventionTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [haveIntervention, setHaveIntervention] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedUserIsCurrentUser, setSelectedUserIsCurrentUser] =
    useState(true);

  const [colorTotal, setColorTotal] = useState("inherit");

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const selectedSchoolYear = props.selectedSchoolYear;
  const selectedUser = props.selectedUser;
  
  let totalHours = rows.reduce((acc, row) => acc + row.TotalEqTD, 0);

  useEffect(() => {}, [rows]);

  useEffect(() => {
    if (!selectedSchoolYear) return;
    setLoading(true);

    // Determine the user to fetch
    const userToFetch =
      selectedUser.id === currentUser.id ? currentUser : selectedUser;
    setSelectedUserIsCurrentUser(userToFetch.id === currentUser.id);

    // Fetch the interventions of the user
    fetchImplicatedInterventions(userToFetch.id, selectedSchoolYear)
      .then((interventions) => {
        const transformedData = interventions.map((item) => ({
          codeEc: item.codeEC,
          anneeScolaire: item.anneeScolaire,
          CM: item.cm,
          TD: item.td,
          TP: item.tp,
          TotalEqTD: item.totalEqTD,
        }));

        setRows(transformedData);
        setHaveIntervention(transformedData.length > 0);

        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((error) => {
        setHaveIntervention(false);
        setLoading(false);
      });
  }, [selectedSchoolYear, selectedUser]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: 300 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Paper
          sx={{
            width: "100%",
            backgroundColor: !haveIntervention ? "var(--bg-200)" : "inherit",
          }}
        >
          {!haveIntervention ? (
            selectedUserIsCurrentUser ? (
              <Typography variant="h6" align="center" sx={{ margin: 2 }}>
                Vous n'avez pas encore effectué d'interventions en{" "}
                <b>{selectedSchoolYear?.anneeScolaire}</b>
                <br />
                {currentUser.role.nom !== "Directeur" &&
                currentUser.role.nom !== "Administration" &&
                currentUser.role.nom !== "Responsable EC" &&
                currentUser.role.nom !== "Responsable de domaine" ? (
                  "Vos interventions seront renseignées par votre responsable et apparaîtront ici."
                ) : (
                  <span>
                    Vous pouvez en ajouter une dès à présent en cliquant sur
                    l'onglet <b>Fiche de cours</b>
                  </span>
                )}
              </Typography>
            ) : (
              <Typography variant="h6" align="center" sx={{ margin: 2 }}>
                {selectedUser.name} {selectedUser.surname} n'a pas effectué
                d'interventions en <b>{selectedSchoolYear?.anneeScolaire}</b>
              </Typography>
            )
          ) : (
            <>
              {/* Scrollable table */}
              <TableContainer
                sx={{
                  maxHeight: 500,
                  backgroundColor: "lightgrey",
                  borderRadius: 2,
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <b>{column.label}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row, index) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.codeEc}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "#f2f2f2" : "white",
                          }}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination only if there are more than 10 interventions */}
              {rows.length >= 10 && (
                <TablePagination
                  labelRowsPerPage={"Lignes par page"}
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </>
          )}
        </Paper>
      )}

      {!loading && haveIntervention && (
        <Box className="total-hour-container">
          <Typography
            variant="h6"
            className="total-hour-text"
            textAlign="right"
            sx={{ color: colorTotal }}
          >
            <b>Total EqTD</b> : {totalHours} h
          </Typography>
        </Box>
      )}
    </>
  );
}
