import {
    GridToolbarContainer,
    GridToolbarDensitySelector, GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import DialogCreateFiche from "./dialogCreateFiche";
import { useCallback, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import "../../style/Fiche.css";

export function EditToolbar(props) {
  const { schoolYears, selectedSchoolYear, setSelectedSchoolYear, isDirector } = props;
  const [openFiche, setOpenFiche] = React.useState(false);

  const handleClick = () => {
    setOpenFiche((prevOpenFiche) => !prevOpenFiche);
  };

  const handlePopUp = useCallback(
    (idFiche) => {
      setOpenFiche((prevOpenFiche) => !prevOpenFiche);
    },
    [setOpenFiche],
  );

  return (
    <GridToolbarContainer className="toolbar">
      <div>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled = {!isDirector}>
          Ajouter une fiche
        </Button>
        <GridToolbarDensitySelector />
      <GridToolbarFilterButton/>
      </div>

      <Autocomplete
        size="small"
        disableClearable
        className="select-school-year-toolbar-fiche"
        options={schoolYears}
        getOptionLabel={(option) => option.anneeScolaire ?? ""}
        defaultValue={selectedSchoolYear}
        renderInput={(params) => (
          <TextField {...params} label="Année scolaire" />
        )}
        value={selectedSchoolYear}
        onChange={(event, value) => setSelectedSchoolYear(value)}
      />
      <DialogCreateFiche
        openFiche={openFiche}
        handlePopUp={handlePopUp}
        data={null}
      />
    </GridToolbarContainer>
  );
}
