import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Box,
  Button, Select, MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import FreeSoloCreateOptionDialog from "../FreeSoloCreateOptionDialog";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { DialogAddIntervenant } from "../../Shared/DialogAddIntervenant";
import { fetchActiveUsers } from "../../Shared/CallAPI/Fetch/fetchActiveUsers";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import dayjs from "dayjs";
import { addIntervention } from "../api_requests/addIntervention";
import toast, { Toaster } from "react-hot-toast";
import { modifyIntervention } from "../api_requests/modifyIntervention";
import { deleteIntervention } from "../api_requests/deleteIntervention";
import {updateEqTD} from "../api_requests/updateEqTD";
import {DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridColDef, GridRowModes} from "@mui/x-data-grid";
import { v4 as uuidv4 } from 'uuid';
import FreeSoloEtudiant from "./FreeSoloEtudiant";
import {addInterventionTutore} from "../api_requests/addInterventionTutore";
import {modifyInterventionTutore} from "../api_requests/modifyInterventionTutore";
import {renderIntervenant, renderIntervenantFreeSolo, RenderIntervenantFreeSolo} from "./newRenderInterv";
import {renderEtudiant, RenderEtudiantFreeSolo} from "./newRenderEtudiant";

export default function IntervenantsTableTutore(props) {
  const {
    allUsers,
    intervenants,
    setIntervenants,
    newIntervenant,
    setNewIntervenant,
    types,
    roles,  
    setAllUsers,
    totalIsTooHight,
    ficheId,
    annee,
    disableAddInterv,
    dateMax,
    coefA,
    coefB,
    coefC,
    domaines,
    totalEqTD,
    setTotalEqTD,
    tdFiche,
      budget,
  } = props;
  const [editIndex, setEditIndex] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const currentDate = dayjs().format("DD/MM/YYYY");
  const [tooltipText, setTooltipText] = useState("Sauvegarder");
  const [intervenantEdited, setIntervenantEdited] = useState([]);
  const [rows, setRows] = React.useState(intervenants);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const isDateAfterMax = dayjs().isAfter(dayjs(dateMax).add(1, 'day'));
  const isDirector =  JSON.parse(localStorage.getItem("user")).role.id === 1;
  const [wrongInput, setWrongInput] = useState(false);
  const [listEtudiants, setListEtudiants] = useState([]);
  const [oldRows, setOldRows] = useState([]);
  const [newValueInterv, setNewValueInterv] = React.useState(null);
  
  useEffect(() => {
    setTooltipText(
      !totalIsTooHight ? "Sauvegarder" : "Le total dépasse le budget",
    );
    
  }, [totalIsTooHight]);
  
  useEffect(() => {
    const etudiants = [...new Set(intervenants.map(intervenant => intervenant.etudiant).filter(Boolean))].map(etudiant => ({ title: etudiant }));
    setListEtudiants(etudiants);
    const rowsFormatted = intervenants.map((intervenant) => {
        return {
            
            id: intervenant.id,
            userId : intervenant.userId,
            nom: intervenant.nomIntervenant + " " + intervenant.prenomIntervenant,
            totalEqTD: intervenant.totalEqTD,
            taux: intervenant.tauxEncadrement,
            date_modif: dayjs(intervenant.dateModif).format("DD/MM/YYYY"),
            etudiant: intervenant.etudiant,
        };
        })
    setRows(rowsFormatted);
    
  }, [intervenants]);
  

  const handleAddIntervention = () => {
    const id = uuidv4();
    setWrongInput(true);  
    const newIntev = {
      id : id,
      nomIntervenant: JSON.parse(localStorage.getItem("user")).name,
      prenomIntervenant: JSON.parse(localStorage.getItem("user")).surname,
      nom : JSON.parse(localStorage.getItem("user")).surname + " " + JSON.parse(localStorage.getItem("user")).name,
      userId: JSON.parse(localStorage.getItem("user")).id,
      taux: 0,
      totalEqTD: 0,
      anneeScolaire: annee,
      date_modif : currentDate,
      ficheId : ficheId,
      td : 0,
      isNew : true,
    }
    setNewIntervenant(newIntev);
    setRows((prev) => [newIntev, ...prev]);
    setRowModesModel((prev) => ({ ...prev, [id]: { mode: GridRowModes.Edit } }));
    setIsEditing(true);
  };
  

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const newUserReload = () => {
    fetchActiveUsers()
      .then((user) => setAllUsers(user))
      .catch((error) => console.log(error));
  };
  
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setWrongInput(false);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setOldRows(rows);
  };

  const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    
    if(typeof id !== 'string')
    {
      const promise = deleteIntervention(id).then((msg) => {
        setRows(rows.filter((row) => row.id !== id));
        setIntervenants(intervenants.filter((interv) => interv.id !== id));
        return msg;
      });
      toast.promise(promise, {
        loading: "Suppression de l'intervention...",
        success: (msg) => <b>{msg}</b>,
        error: (msg) => <b>{msg}</b>,
      });
    }
    else {
        setRows(rows.filter((row) => row.id !== id));
    }
    
    
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
     
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  
  const isStudentUnder1 = (editedRow,oldRow) => {
    const etudiant = editedRow.etudiant;
    
    const etudiants = rows.filter((row) => (row.etudiant === etudiant));
    let newTaux =  editedRow.taux;
    const oldTaux = oldRow?.taux;
    const total = etudiants.reduce((acc, curr) => acc + curr.taux, 0) + newTaux - oldTaux;
      if (total > 1)
      {
          return true;
      }
      else
      {
          setWrongInput(false);
          return false;
      }
    }

  const totalEqTDIsUnderBudget = (editedRow) => {
    console.log("editedRow in", editedRow);
    const oldRows = rows.filter((row) =>row.id !== editedRow.id);
    //const totalTD = oldRows.reduce((acc, row) => acc + row.td, 0) + editedRow.td;
    console.log("totalTD",Math.round(editedRow.totalEqTD));
    const totalEqTD = oldRows.reduce((acc, row) => acc + row.totalEqTD, 0) + editedRow.totalEqTD;
    console.log("totalEqTD", totalEqTD);
    return totalEqTD > budget; // Assurez-vous que la variable `budget` est définie
    
  };
  
  const processRowUpdate = (newRow,oldRow) => {
    const editedRow = {
      ...newRow,
      isNew: false,
      nom: allUsers.find(user => user.id === newRow.userId)?.surname + " " + allUsers.find(user => user.id === newRow.userId)?.name,
      nomIntervenant: allUsers.find(user => user.id === newRow.userId)?.surname,
      prenomIntervenant: allUsers.find(user => user.id === newRow.userId)?.name,
      fileStatus: allUsers.find(user => user.id === newRow.userId)?.fileStatus,
    };
    const res =  isStudentUnder1(editedRow,oldRow);
    // const overBudget = totalEqTDIsUnderBudget(editedRow);
    if (!res)
    {
      setRows(rows.map((row) => (row.id === newRow.id ? editedRow : row)));
      editedRow.totalEqTD = editedRow.taux * tdFiche //* coefB;
      if (typeof editedRow.id === 'string')
      {
        const promise = addInterventionTutore({
          userId: editedRow.userId,
          ficheId: ficheId,
          totalEqTD:editedRow.totalEqTD,
          etudiant: editedRow.etudiant,
          tauxEncadrement: editedRow.taux,
          anneeScolaire: annee,
          td : null,
          // td : editedRow.taux * tdFiche,
          fileStatus: editedRow.fileStatus,
        }).then((msg) => {
          editedRow.id = msg.data.id;
          setRows(rows.map((row) => (row.id === newRow.id ? editedRow : row)));
          const newInterv = {
            id: editedRow.id,
            userId: editedRow.userId,
            nomIntervenant: editedRow.nomIntervenant,
            prenomIntervenant: editedRow.prenomIntervenant,
            nom : editedRow.nom,
            tauxEncadrement: editedRow.taux,
            totalEqTD: editedRow.totalEqTD,
            etudiant : editedRow.etudiant,
            // td : editedRow.taux * tdFiche,
            td : null,
            anneeScolaire: annee,
            date_modif : currentDate,
            ficheId : ficheId,
          }
          setIntervenants([...intervenants, newInterv]);
          return msg.message;
        });
        toast.promise(promise, {
          loading: "Ajout de l'intervention...",
          success: (msg) => <b>{msg}</b>,
          error: (msg) => <b>{msg}</b>,
        });
      }
      else
      {
        const promise = modifyInterventionTutore({
          userId: editedRow.userId,
          ficheId: ficheId,
          totalEqTD:editedRow.totalEqTD,
          etudiant: editedRow.etudiant,
          tauxEncadrement: editedRow.taux,
          anneeScolaire: annee,
          id: editedRow.id,
          //td : editedRow.taux * tdFiche,
          td : null,
          fileStatus: editedRow.fileStatus,
        }).then((msg) => {
          setRows(rows.map((row) => (row.id === newRow.id ? editedRow : row)));
          const newInterv = {
            id: editedRow.id,
            userId: editedRow.userId,
            nomIntervenant: editedRow.nomIntervenant,
            prenomIntervenant: editedRow.prenomIntervenant,
            nom : editedRow.nom,
            tauxEncadrement: editedRow.taux,
            totalEqTD: editedRow.totalEqTD,
            etudiant : editedRow.etudiant,
            td : null,
            // td : editedRow.taux * tdFiche,
            anneeScolaire: annee,
            date_modif : currentDate,
            ficheId : ficheId,
          }
          setIntervenants(intervenants.map((interv) => (interv.id === editedRow.id ? newInterv : interv)));
          return msg;
        });
        toast.promise(promise, {
          loading: "Modification de l'intervention...",
          success: (msg) => <b>{msg}</b>,
          error: (msg) => <b>{msg}</b>,
        });
    }
      return editedRow;
    }
    else
    {
      toast.error("Le total des taux d'encadrement ne doit pas dépasser 1 !!");
      //editedRow.taux = oldRow.taux;
      handleCancelClick(editedRow.id);
      return oldRow
    }
   
    
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  
 useEffect(() => {
  const isAnyRowInEditMode = Object.values(rowModesModel).some(
    (row) => row.mode === GridRowModes.Edit
  );
  if (isAnyRowInEditMode) {
    setIsEditing(true);
  } else {
    setIsEditing(false);
  }
}, [rowModesModel]);

  const CustomGridActionsCellItem = React.forwardRef(({ touchRippleRef, ...props }, ref) => (
      <GridActionsCellItem ref={ref} {...props} />
  ));
   
  
  
  const columns  =  [
    //{ field: "nom",headerAlign: 'center', headerName: "Nom prénom", width: 200, align: "center",editable: {isDirector} },
      
{field : "userId", headerName : " <b>Intervenant</b> (NOM prenom)", headerAlign: "center", align: "center", display: 'flex',
        renderHeader: (params) => (
            <span dangerouslySetInnerHTML={{ __html: params.colDef.headerName }} />
        ),
      renderCell: renderIntervenant,
      renderEditCell: (params) => <RenderIntervenantFreeSolo {...params} allUsers={allUsers} setWrongInput={setWrongInput} />,
      editable : true, width: 300}, 
      
    { field: "taux", headerAlign: 'center',headerName: "Taux d'encradrement", width: 80, align: "center", type : "number",editable: true },
    { field: "totalEqTD", headerAlign: 'center',headerName: "TotalEqTD", width: 80, align: "center",type : "number",editable: false },
    { field: "date_modif", headerAlign: 'center',headerName: "Date de modif.", width: 150, align: "center", editable: false },
    {
      field: "etudiant",
      headerName: " <b>Etudiant</b> (NOM prenom)",
      renderHeader: (params) => (
          <span dangerouslySetInnerHTML={{ __html: params.colDef.headerName }} />
      ),
      display : "flex",
      headerAlign: 'center',
      cellClassName: 'selectEtudiant',
      width: 300,
      editable: true,
      align: "center",
      renderCell : renderEtudiant,
      renderEditCell : (params) => <RenderEtudiantFreeSolo{...params} listEtudiants={listEtudiants} setListEtudiants={setListEtudiants} setWrongInput={setWrongInput} />,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Tooltip title={wrongInput ? "Remplissez tous les champs" : "Sauvegarder"} placement="top">
          <span>
            <CustomGridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
                disabled={wrongInput}
            />
          </span>
            </Tooltip>,
            <Tooltip title={"Annuler"} placement="top">
          <span>
            <CustomGridActionsCellItem
                icon={<CancelIcon/>}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
            />
          </span>
            </Tooltip>
        ,
        ];
        }

        return [
          <Tooltip title={"Editer"} placement={"top"}>
        <span>
          <CustomGridActionsCellItem
              icon={<EditIcon/>}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={isEditing ||  (disableAddInterv && !isDirector)}
          />
        </span>
          </Tooltip>,
          <Tooltip title={"Supprimer"} placement={"top"}>
        <span>
          <CustomGridActionsCellItem
              icon={<DeleteIcon/>}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
              disabled={isEditing ||  (disableAddInterv && !isDirector)}
          />
        </span>
          </Tooltip>
      ,
      ];
      },
    },
      
  
  ]
   

  return (
    <div>
      <Toaster />
      <Box
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddIntervention}
          disabled={isEditing ||  (disableAddInterv && !isDirector)}
        >
          Ajouter une intervention
        </Button>
        <Typography variant="caption" sx={{ color: `${isDateAfterMax ? "red" : "grey" }`}}>
          {`Date limite de dépôt ${isDateAfterMax ? "dépassée " : " "}: ${dateMax.format("DD/MM/YYYY")}`}
        </Typography>
      </Box>
      <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
      />
      <DialogAddIntervenant
        roles={roles}
        types={types}
        open={isDialogOpen}
        handleClose={handleClose}
        allUser={allUsers}
        newUserReload={newUserReload}
      />
    </div>
  );
}
