import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { drawerCloseWidth } from "../Shared/DrawerBar";
import React, { useEffect, useState } from "react";
import { tradFrGrid } from "../Shared/TxtFr";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import FileOpenIcon from "@mui/icons-material/FileOpen";

export default function GridBoard({ data, handleClickDetails }) {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: "promotion",
      headerName: "Promotion",
      width: 150,
      editable: false,
      align: "center",
    },
    {
      field: "parcours",
      headerName: "Parcours",
      width: 150,
      editable: false,
      align: "center",
    },
    {
      field: "semestre",
      headerName: "Semestre",
      width: 150,
      editable: false,
      align: "center",
    },
    {
      field: "annee",
      headerName: "Année",
      width: 150,
      editable: false,
      align: "center",
    },
    {
      field: "CM",
      headerName: "CM FaF",
      type: "number",
      width: 110,
      editable: false,
    },
    {
      field: "TD",
      headerName: "TD FaF",
      type: "number",
      editable: false,
      width: 110,
      valueGetter: (value, row) => {
        console.log("row", row);
        const { CM, TP, TD, Taux_encadrement } = row;
        return (CM === null && TP === null && TD === null && Taux_encadrement !== null) ? Taux_encadrement : TD;
      },
    },
    {
      field: "TP",
      headerName: "TP FaF",
      type: "number",
      width: 110,
      editable: false,
    },
    {
      field: "TotalEqTD",
      headerName: "Total EqTD",
      type: "number",
      editable: false,
      width: 130,
    },
    {
      field: "details",
      type: "actions",
      headerName: "Détails",
      width: 70,
      align: "center",
      cellClassName: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BarChartRoundedIcon />}
          label="Voir Détails"
          className="textPrimary"
          onClick={() => handleClickDetails(params.row)}
          color="inherit"
        />,
      ],
    },
  ];

  // Add a strong tag to the header of each column
  columns.forEach((column) => {
    column.renderHeader = (params) => (
      <strong>{params.colDef.headerName}</strong>
    );
  });

  useEffect(() => {
    setRows(data);
  }, [data]);
  return (
    <Box className="grid-board">
      <DataGrid
        localeText={tradFrGrid}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          filter: {
            filterModel: {
              items: [
                {
                  columnField: "id",
                  operatorValue: "contains",
                  value: "", // You can set a default filter value here if needed
                },
              ],
            },
          },
        }}
      />
    </Box>
  );
}
