import Box from "@mui/material/Box";
import FileOpenIcon from "@mui/icons-material/FileOpen";

import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { EditToolbar } from "./EditToobar";
import { fetchImplicatedFiche } from "../Shared/CallAPI/Fetch/fetchImplicatedFiche";
import { useNavigate } from "react-router-dom";
import DialogFicheSimple from "./FichesSimple/dialogFicheSimple";
import { fetchFicheById } from "../Shared/CallAPI/Fetch/fetchFicheById";
import { fetchAllFichesBySchoolYear } from "../Shared/CallAPI/Fetch/fetchAllFiches";
import { tradFrGrid } from "../Shared/TxtFr";
import toast from "react-hot-toast";
import "../../style/Fiche.css";
import { fetchAnnees } from "../Shared/CallAPI/Fetch/fetchAnnees";
import DialogProjetCollectif from "./FichesProjectColletif/dialogProjetCollectif";
import DialogProjetTutore from "./FichesProjetTutore/dialogProjetTutore";

export default function GridFiches() {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openFiche, setOpenFiche] = useState(false);
  const [openProjetCollectif, setOpenProjetCollectif] = useState(false);
  const [openProjetTutore, setOpenProjetTutore] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDirector, setIsDirector] = useState(false);
  
  const allTypes = ["Fiche EC", "Projet Collectif", "Projet Tutore"];
  
  useEffect(() => {
    // Fetch the school years
    fetchAnnees()
      .then((data) => {
        if (data === null) {
          return;
        }
        setSchoolYears(data);

        // Set selected school year to the current year by default
        const currentYear = data.find(
          (year) => year.currentAnneeScolaire === true,
        );
        setSelectedSchoolYear(currentYear);
      })
      .catch((error) =>
        toast.error("Erreur lors de la récupération des années scolaires"),
      );
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    

    // Only proceed if selectedSchoolYear is not null
    if (selectedSchoolYear) {
      if (user.role.nom !== "Directeur" && user.role.nom !== "Administration" ) {
        // If it's not the director, fetch the fiches where the user is implicated
        // If the user is responsable of EC, he can see only this fiche
        // If the user is responsable of a domain, he can see all fiches of this domain
        fetchImplicatedFiche(user, user.id, selectedSchoolYear)
          .then((fiches) => {
            const transformedData = fiches.map((item) => ({
              id: item.id,
              codeEc: item.codeEC,
              libelle: item.libelle,
              domaine: item.domaine.nom,
              promotion: item.promotion.nom,
              semestre: item.semestre.nom,
              parcours: item.parcours.nom,
              heures: item.cm + item.td + item.tp,
              budget: item.budget,
              responsable: item.responsable.nom + " " + item.responsable.prenom,
              anneeScolaire: item.anneeScolaire,
              type: item.type,
            }));
              setRows(transformedData);
          })
            .catch((error) => {
              setRows([]);
              console.log(error);
            });
      } else {
        if(user.role.nom === "Directeur" )
        {
          setIsDirector(true);
        }        
        // The director have access to all fiches
        fetchAllFichesBySchoolYear(selectedSchoolYear)
          .then((fiches) => {
            const transformedData = fiches.map((item) => ({
              id: item.id,
              codeEc: item.codeEC,
              libelle: item.libelle,
              domaine: item.domaine.nom,
              promotion: item.promotion.nom,
              semestre: item.semestre.nom,
              parcours: item.parcours.nom,
              heures: item.cm + item.td + item.tp,
              budget: item.budget,
              responsable: item.responsable.nom + " " + item.responsable.prenom,
              anneeScolaire: item.anneeScolaire,
              type : item.type
            }));   
              setRows(transformedData);
          })
            .catch((error) => {
              setRows([]);
              console.log(error);
            });
      }
    }
  }, [selectedSchoolYear]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const closeDialog = () => {
    setOpenFiche(false);
    setOpenProjetCollectif(false);
    setOpenProjetTutore(false);
    };
  
  const handlePopUp = useCallback((idFiche) => {
    
    if (typeof idFiche === "number" || Number.isInteger(idFiche)) {
      // Fetch the fiche data by id and update the state
      fetchFicheById(idFiche)
        .then((fiche) => {
          setData(fiche);
          // Toggle the openFiche state
          const dialogType = fiche === null ? "Inconnu" : fiche.type;
          switch (dialogType) {
            case "Fiche EC":
              setOpenFiche(true);
              break;
            case "Projet Collectif":
              setOpenProjetCollectif(true);
              break
            case "Projet Tutore":
              setOpenProjetTutore(true);
              break
            default:
              break;

          }
          
        })
        .catch((error) => console.error(error));
    }
        
   
  }, []);

  useEffect(() => {
    
  }, []);

  const columns = [

    { field: "domaine", headerAlign: 'center',headerName: "Domaine", width: 300, align: "center" },
    { field: "semestre",headerAlign: 'center', headerName: "Semestre", width: 80, align: "center" },
    { field: "codeEc", headerAlign: 'center',headerName: "Code", width: 200, align: "center" },
    { field: "type", headerName: "Type",headerAlign: 'center', width: 200, align: "center", type: "singleSelect",
      valueOptions: allTypes, },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FileOpenIcon />}
          label="Voir"
          onClick={() => handlePopUp(params.id)}
          color="inherit"
        />,
      ],
    },
  ];
  // Add strong title to each column
  columns.forEach((column) => {
    column.renderHeader = (params) => (
      <strong>
        {params.colDef.headerName}
      </strong>
    );
  });

  return (
    <Box className="grid-fiches">
      <DialogFicheSimple
          openFiche={openFiche}
          handlePopUp={handlePopUp}
          closeDialog={closeDialog}
          data={data}
          loading={loading}
          setLoading={setLoading}
      />
      <DialogProjetCollectif
          openFiche={openProjetCollectif}
          handlePopUp={handlePopUp}
          closeDialog={closeDialog}
          data={data}
          loading={loading}
          setLoading={setLoading}
      />
      <DialogProjetTutore
          openFiche={openProjetTutore}
          handlePopUp={handlePopUp}
          closeDialog={closeDialog}
          data={data}
          loading={loading}
          setLoading={setLoading}
      />


      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        clipboardCopyCellDelimiter=";"
        localeText={tradFrGrid}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { schoolYears, selectedSchoolYear, setSelectedSchoolYear, isDirector },
          pagination: { labelRowsPerPage: "Lignes par pages" },
        }}
        sortModel={[{ field: 'type', sort: 'desc' }]}
        
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
