import Box from "@mui/material/Box";
import DrawerBar from "../Shared/DrawerBar";
import * as React from "react";
import GridFiches from "./GridFiches";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export default function Fiches() {
  const navigate = useNavigate();
  useEffect(() => {
    const role = JSON.parse(localStorage.getItem("user")).role;
    if (
      role.nom !== "Directeur" &&
      role.nom !== "Responsable de domaine" &&
      role.nom !== "Responsable EC" && 
      role.nom !== "Lecteur" &&
      role.nom !== "Administration"
    ) {
      toast.error("Vous n'avez pas accès à cette page");
      navigate(-1);
    }
  }, []);

  return (
    <Box className="container">
      <DrawerBar selected={"/fiches"} />
      <GridFiches />
    </Box>
  );
}
