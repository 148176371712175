import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridActionsCellItem,
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import PercentCell from "./percentCell";
import { fetchDomaine } from "../Shared/CallAPI/Fetch/fetchDomaine";
import "../../style/Comptes.css";
import { ButtonChangeUnit } from "./ButtonChangeUnit";
import { tradFrGrid } from "../Shared/TxtFr";
import { fetchActivityByDomainBySchoolYear } from "../Shared/CallAPI/Fetch/fetchActivityByDomain";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import DetailsRowDialog from "./DetailsRowDialog";
import { fetchDetailsActivityByDomainByUserAndSchoolYear } from "../Shared/CallAPI/Fetch/fetchDetailActivityByDomain";
import TotalsBox from "./TotalBox";
import { fetchAnnees } from "../Shared/CallAPI/Fetch/fetchAnnees";

const transformRows = (rows, domaines) => {
  const visibleDomaine = JSON.parse(localStorage.getItem("visibleDomaines"));
  let trueDomaines;
  if(visibleDomaine !== null) {
    trueDomaines = Object.keys(visibleDomaine).filter(domain => visibleDomaine[domain]);
  }
  else {
    trueDomaines = domaines;
  }
  return rows.map((row) => {
    const newRow = { ...row };
    let totalHeures = 0;
    trueDomaines.forEach((domaine) => {
      newRow[domaine] = row[domaine]?.total ?? 0;
      totalHeures += newRow[domaine];
    });

    newRow.TotalHeures = totalHeures;
    return newRow;
  });
};
export default function CompteGrid() {
  const [rows, setRows] = useState([]);
  const [domaines, setDomaines] = useState([]);
  const [valueType, setValueType] = useState(0);
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [detailsData, setDetailsData] = useState([]);
  const [totals, setTotals] = useState({});
  const [globalTotal, setGlobalTotal] = useState(0);
  const [showGlobalTotals, setShowGlobalTotals] = useState(true);
  const navigate = useNavigate();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  
  // This state change to !filerApplied when filter are applied to the grid
  const [filterApplied, setFilterApplied] = useState(false);
  const [sortApplied, setSortApplied] = useState(false);
  const [changeColumn, setChangeColumn] = useState(false);

  const apiRef = useGridApiRef();

  const handleToggleTotals = () => {
    setShowGlobalTotals(!showGlobalTotals);
  };

  useEffect(() => {
    const visibleDomaines = JSON.parse(localStorage.getItem("visibleDomaines"));
    if (visibleDomaines) {
      setColumnVisibilityModel(visibleDomaines); 
    }
  }, []);
  
  

  useEffect(() => {
    fetchAnnees().then((data) => {
      if (data === null) {
        return;
      }
      setSchoolYears(data);
      const currentYear = data.find(
        (year) => year.currentAnneeScolaire === true,
      );
      setSelectedSchoolYear(currentYear);
    });
  }, []);

  useEffect(() => {
    if (selectedSchoolYear) {
      fetchActivityByDomainBySchoolYear(selectedSchoolYear)
        .then((rowsReq) => {
          const rowsWithId = rowsReq.map((row, index) => ({
            id: index + 1,
            ...row,
          }));
          setRows(rowsWithId);
        })
        .catch((error) => console.log(error));
    }
    fetchDomaine()
      .then((domaines) => {
        const domainesNomDomaine = domaines.map(
          (domaine) => domaine.nomDomaine,
        );
        setDomaines(domainesNomDomaine);
        const visibleDomaines = JSON.parse(localStorage.getItem("visibleDomaines")) || {};
        domaines.forEach((domaine) => {
          if (!visibleDomaines.hasOwnProperty(domaine.nomDomaine)) {
            visibleDomaines[domaine.nomDomaine] = true;
          }
        });
        localStorage.setItem("visibleDomaines", JSON.stringify(visibleDomaines));
      })
      .catch((error) => console.log(error));
    localStorage.getItem("valueType")
      ? setValueType(parseInt(localStorage.getItem("valueType")))
      : setValueType(0);
  }, [selectedSchoolYear, navigate]);

  useEffect(() => {
    const newTotals = {};
    let newGlobalTotal = 0;
    const rowsToIterate = showGlobalTotals
      ? transformRows(rows, domaines)
      : gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);

    let trueDomaines;
    const visibleDomaine = JSON.parse(localStorage.getItem("visibleDomaines"));
    if(visibleDomaine !== null) {
      trueDomaines = Object.keys(visibleDomaine).filter(domain => visibleDomaine[domain]);
    }
    else {
      trueDomaines = domaines;
    }
    trueDomaines.forEach((dom) => {
      const totalForDomain = rowsToIterate.reduce((acc, row) => {
        const currentRow = showGlobalTotals ? row : row.model;
        let domainData = 0; // Ensure domainData is initialized as a number
        if (valueType === 0) {
          domainData = currentRow[dom] || 0; // Ensure domainData is a number
        } else if (valueType === 1) {
          const ratio = currentRow[dom] / currentRow.TotalHeures;
          const roundedRatio = parseFloat(ratio.toFixed(2));
          domainData = currentRow[dom] ? roundedRatio : 0;
        }
        return acc + domainData;
      }, 0);
      newTotals[dom] = parseFloat(totalForDomain.toFixed(2));
    });
    const sum = Object.values(newTotals).reduce((acc, value) => acc + value, 0);

    
    if (valueType === 0) {
      newGlobalTotal =  sum ;
    } else if (valueType === 1) {
      newGlobalTotal = rowsToIterate.reduce((acc, row) => {
        if (showGlobalTotals) {
          if (row.TotalHeures > 0) {
            return acc + 1;
          } else {
            return acc;
          }
        } else {
          const sumHourInterv = Object.keys(row.model)
              .filter(key => trueDomaines.includes(key))
              .reduce((acc, key) => acc + row.model[key], 0);
          if (sumHourInterv > 0) {
            return acc + 1;
          } else {
            return acc;
          }
        }
      }, 0);
    }

    setTotals(newTotals);
    setGlobalTotal(newGlobalTotal);
  }, [domaines, apiRef, rows, valueType, filterApplied,sortApplied, showGlobalTotals,changeColumn]);

  const handleClickDetails = (id)  => {
    const rowData = rows.find((row) => row.id === id);

    if (rowData) {
      const userId = rowData.Id;
      const schoolYear = selectedSchoolYear;
      const domainsId = Object.keys(rowData)
        .filter((key) => {
          const domain = rowData[key];
          return (
            domain &&
            typeof domain === "object" &&
            domain.id !== null &&
            domain.total > 0
          );
        })
        .map((key) => rowData[key].id);

      fetchDetailsActivityByDomainByUserAndSchoolYear(
        userId,
        schoolYear,
        domainsId,
      ).then((details) => {
        setDetailsData(details);
      });
    }

    setSelectedRowData(rowData);
    setDetailsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDetailsDialogOpen(false);
    setSelectedRowData(null);
  };

  const areAllTotalsZero = (row) => {
    return (
      domaines.every((dom) => {
        const domainData = row[dom];
        return !domainData || domainData.total === 0;
      }) && row.TotalHeures === 0
    );
  };

  // Définissez un tableau des domaines spéciaux à placer à la fin
  const specialDomains = ["Sport", "Langues", "SHS"];

// Triez le tableau des domaines
  const sortedDomaines = domaines.sort((a, b) => {
    const aSpecial = specialDomains.includes(a);
    const bSpecial = specialDomains.includes(b);

    if (aSpecial && !bSpecial) return 1;
    if (!aSpecial && bSpecial) return -1;
    if (aSpecial && bSpecial) {
      return specialDomains.indexOf(a) - specialDomains.indexOf(b);
    }
    return a.localeCompare(b); // Tri alphabétique pour les autres domaines
  });

  const columns = [
    { field: "Nom", headerName: "Nom", width: 200 },
    { field: "Prenom", headerName: "Prénom", width: 175 },
    {
      field: "Type",
      headerName: "Contrat",
      width: 150,
    },
    ...sortedDomaines.map((dom) => ({
      field: dom,
      headerName: dom,
      width: 90,
      align: "center",
      type: "number",
      valueType: "number",
      renderCell: (params) => {
        const domainData = params.row[dom];
        const roundedDomainData = domainData ? parseFloat(domainData.toFixed(2)) : 0;
        return (
            <PercentCell
                value={roundedDomainData}
                type={valueType}
                totalHours={params.row.TotalHeures}
            />
        );
      },
    })),
    {
      field: "TotalHeures",
      headerName: "TotalEqTD",
      width: 90,
      align: "center",
      renderCell: (params) => `${parseFloat(params.value.toFixed(2))} h`,
    },
    {
      field: "details",
      type: "actions",
      headerName: "Détails",
      width: 70,
      cellClassName: "actions",
      getActions: (params) => {
        const { row } = params;
        const disabled = areAllTotalsZero(row);

        return [
          <GridActionsCellItem
            icon={<BarChartRoundedIcon />}
            label="Voir Détails"
            className="textPrimary"
            onClick={() => handleClickDetails(params.id)}
            color="inherit"
            disabled={disabled}
          />,
        ];
      },
    },
  ];

  columns.forEach((column) => {
    column.renderHeader = (params) => (
      <strong>{params.colDef.headerName}</strong>
    );
  });

  const transformedRows = transformRows(rows, domaines);

  return (
    <>
      <Box className="pourcent-grid-container">
        <TableContainer sx={{ maxHeight: "100%" }}>
          <DataGrid
            apiRef={apiRef}
            rows={transformedRows}
            columns={columns}
            clipboardCopyCellDelimiter=";"
            onSortModelChange={ () => {
             setSortApplied(!sortApplied) }}
            onFilterModelChange={(_) => {
              setShowGlobalTotals(false);
              setFilterApplied(!filterApplied);
            }}
            onPaginationModelChange={() => {
              setFilterApplied(!filterApplied);
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              // Vérifiez que les colonnes requises ne sont pas masquées
              const requiredColumns = ['Nom', 'Prenom', 'Type', 'TotalHeures', 'details'];
              /// Filtrer le nouveau modèle pour exclure les colonnes requises
              const filteredModel = columns.reduce((acc, column) => {
                if (!requiredColumns.includes(column.field)) {
                  // Si la colonne est dans newModel, utilisez sa valeur, sinon mettez true
                  acc[column.field] = newModel.hasOwnProperty(column.field) ? newModel[column.field] : true;
                }
                return acc;
              }, {});
              
              setChangeColumn(!changeColumn);
              setColumnVisibilityModel(filteredModel)
              localStorage.setItem("visibleDomaines", JSON.stringify(filteredModel));
            }}
            localeText={tradFrGrid}
            slots={{
              toolbar: ButtonChangeUnit,
            }}
            slotProps={{
              toolbar: {
                valueType,
                setValueType,
                schoolYears,
                selectedSchoolYear,
                setSelectedSchoolYear,
              },
              pagination: { labelRowsPerPage: "Lignes par pages" },
            }}
            sx={{
              "& .MuiDataGrid-columnHeader": {
                fontWeight: "bold",
              },
            }}
          />
        </TableContainer>
        <DetailsRowDialog
          open={detailsDialogOpen}
          onClose={handleCloseDialog}
          rowData={selectedRowData}
          detailsData={detailsData}
        />
      </Box>
      <TotalsBox
        totals={totals}
        globalTotal={globalTotal}
        valueType={valueType}
        showGlobalTotals={showGlobalTotals}
        handleToggleTotals={handleToggleTotals}
      />
    </>
  );
}
